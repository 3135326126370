import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { PulseLoader } from 'react-spinners';
import './Rankings.css';

const Rankings = () => {
  const [profiles, setProfiles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTopProfiles = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/top-10-profiles`, { withCredentials: true });
        if (Array.isArray(response.data)) {
          setProfiles(response.data);
        } else {
          console.error('API response is not an array:', response.data);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching top profiles:', error);
        setLoading(false);
      }
    };

    fetchTopProfiles();
  }, []);

  if (loading) {
    return (
      <div className="loader-container">
        <PulseLoader color="#36d7b7" />
      </div>
    );
  }

  return (
    <div className="rankings-container">
      <h2>Top 10 Hottest Profiles</h2>
      <ul className="rankings-list">
        {profiles.map((profile, index) => (
          <li key={profile._id} className="ranking-item">
            <span className="rank-number">#{index + 1}</span>
            <img src={profile.profilePicture} alt="Profile" className="ranking-profile-picture" />
            <span className="ranking-username">{profile.username}</span>
            <span className="ranking-score">{profile.rank} pts</span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Rankings;
